import {tryCatchExecute} from "./helpers/tryCatchExecute";

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

window.addEventListener("load", async () => {

  (window as any).CookiebotCallback_OnLoad = function CookiebotCallback_OnLoad() {
    return console.log('CookiebotCallback_OnLoad')
  };

  (window as any).CookiebotCallback_OnDialogInit = function CookiebotCallback_OnDialogInit() {
    return console.log('CookiebotCallback_OnDialogInit')
  };


  (window as any).CookiebotCallback_OnDialogDisplay = function CookiebotCallback_OnDialogDisplay() {
    return console.log('CookiebotCallback_OnDialogDisplay')
  };

  (window as any).CookiebotCallback_OnTagsExecuted = function CookiebotCallback_OnTagsExecuted() {
    return console.log('CookiebotCallback_OnTagsExecuted')
  };

  window.addEventListener('CookiebotOnLoad', () => console.log("CookiebotOnLoad"));
  window.addEventListener('CookiebotOnConsentReady', () => console.log("CookiebotOnConsentReady"));
  window.addEventListener('CookiebotOnDialogInit', () => console.log("CookiebotOnDialogInit"));
  window.addEventListener('CookiebotOnDialogDisplay', () => console.log("CookiebotOnDialogDisplay"));
  window.addEventListener('CookiebotOnTagsExecuted', () => console.log("CookiebotOnTagsExecuted"));


  try {
    const [
      handleMobileAppAdvisory,
      animationsModule,
      insightsModule,
      slidersModule,
      slidersCareerModule,
      cookieModule,
      userComModule,
      handleCustomDropdown,
      newsletterModule,
      contactModule,
      advisoryModule,
      accordionsModule,
      caseStudyGalleryModule,
      videoModule,
      hamburgerModule,
      careerModule,
      categoriesShowcaseModule,
      contactPopupModule,
      resourcesModule,
      downloadModule,
      insightsContentModule,
      dropdownModule,
      downloadPageModule,
      mobileAdditionalModule,
      pressOfficeModule,
      projectsModule,
      tabPaneModule,
      careerFilterSelectModule,
      formatInsightsSliderModule,
      trackingModule,
      storyTimelineModule,
      toggleInsightTagsModule,
      careerTimelineModule,
      jobOfferVerticalGalleryModule,
      scrollComponent,
      cookiesHelper,
      reportPreviewPopupModule,
      copyToClipboard,
      handleFloadtinButton
    ] = await Promise.all([
      import("./content-builders/mobile-app-advisory/mobile-app-advisory"),
      import("./ui/animations"),
      import("./navigation/insights"),
      import("./ui/sliders"),
      import("./ui/slidersCareer"),
      import("./ui/cookie"),
      import("./forms/handleUserCom"),
      import("./forms/customDropdown"),
      import("./forms/newsletter"),
      import("./forms/contact"),
      import("./forms/advisory"),
      import("./ui/accordions"),
      import("./ui/caseStudyGallery"),
      import("./ui/video"),
      import("./navigation/hamburger"),
      import("./content-builders/career/career"),
      import("./content-builders/categories-showcase/categories-showcase"),
      import("./ui/contactPopup"),
      import("./forms/resources"),
      import("./forms/download"),
      import("./content-builders/insights/insights"),
      import("./navigation/dropdown"),
      import("./navigation/download-page"),
      import("./navigation/mobile-additional"),
      import("./content-builders/press-office/press-office"),
      import("./content-builders/portfolio/projects"),
      import("./ui/tab-pane"),
      import("./ui/careerFilterSelect"),
      import("./ui/formatInsightsSlider"),
      import("./tracking/startTracking"),
      import("./ui/story-timeline"),
      import("./ui/toggleInsightTags"),
      import("./ui/careerTimeline"),
      import("./ui/jobOfferVerticalGallery"),
      import("./ui/scrollComponent"),
      import("./helpers/cookies"),
      import("./ui/reportPreviewPopup"),
      import("./buttons/copyToClipboard"),
      import("./forms/floating-button"),
    ]);
    tryCatchExecute(animationsModule.handleStickyNav, "handleStickyNav");
    tryCatchExecute(animationsModule.trustedByHeadingAnimation, "trustedByHeadingAnimation");
    tryCatchExecute(animationsModule.trustedByAnimation, "trustedByAnimation");
    tryCatchExecute(animationsModule.projectAnimations, "projectAnimations");
    tryCatchExecute(animationsModule.slideOnScrollAnimation, "slideOnScrollAnimation");
    tryCatchExecute(handleMobileAppAdvisory.handleMobileAppAdvisory, "handleMobileAppAdvisory");

    tryCatchExecute(insightsModule.handleInsightsBar, "handleInsightsBar");
    tryCatchExecute(insightsModule.closeInsightsBarAtTabletWidth, "closeInsightsBarAtTabletWidth");

    tryCatchExecute(slidersModule.reviewsSlider, "reviewsSlider");
    tryCatchExecute(slidersModule.handleHorizontalScroll, "handleHorizontalScroll");
    tryCatchExecute(slidersModule.fleetConnectSlider, "fleetConnectSlider");
    tryCatchExecute(slidersModule.lifestyleSlider, "lifestyleSlider");
    tryCatchExecute(slidersModule.insightsSlider, "insightsSlider");
    tryCatchExecute(slidersModule.servicesSlider, "servicesSlider");

    tryCatchExecute(slidersCareerModule.careerSliders, "careerSliders");

    tryCatchExecute(handleCustomDropdown.handleCustomDropdown, "handleCustomDropdown");

    tryCatchExecute(cookieModule.handleCookie, "handleCookie");
    tryCatchExecute(userComModule.handleUserCom, "handleUserCom");
    tryCatchExecute(newsletterModule.handleSubmitNewsletter, "handleSubmitNewsletter");
    tryCatchExecute(contactModule.handleSubmitContact, "handleSubmitContact");
    tryCatchExecute(advisoryModule.handleSubmitAdvisoryContact, "handleSubmitAdvisoryContact");

    tryCatchExecute(accordionsModule.handleAccordions, "handleAccordions");
    tryCatchExecute(caseStudyGalleryModule.handleCaseStudiesGallery, "handleCaseStudiesGallery");
    tryCatchExecute(videoModule.playVideo, "playVideo");
    tryCatchExecute(videoModule.playVideoOnClick, "playVideoOnClick");

    tryCatchExecute(hamburgerModule.handleHamburger, "handleHamburger");
    tryCatchExecute(hamburgerModule.closeMobileInsightsAndNavAtDesktopWidth, "closeMobileInsightsAndNavAtDesktopWidth");

    tryCatchExecute(careerModule.handleCareer, "handleCareer");
    tryCatchExecute(categoriesShowcaseModule.handleCategoriesShowcase, "handleCategoriesShowcase");
    tryCatchExecute(contactPopupModule.handleContactPopup, "handleContactPopup");
    tryCatchExecute(resourcesModule.handleSubmitResource, "handleSubmitResource");
    tryCatchExecute(downloadModule.handleSubmitDownload, "handleSubmitDownload");
    tryCatchExecute(downloadModule.handleSubmitNonHubspotForms, "handleSubmitNonHubspotForms");
    tryCatchExecute(insightsContentModule.handleInsights, "handleInsights");

    tryCatchExecute(dropdownModule.handleDropdownMenu, "handleDropdownMenu");
    tryCatchExecute(downloadPageModule.downloadPageHandleScrolToTop, "downloadPageHandleScrolToTop");
    tryCatchExecute(mobileAdditionalModule.mobileNavExtendTogglerHandler, "mobileNavExtendTogglerHandler");
    tryCatchExecute(pressOfficeModule.handlePressOffice, "handlePressOffice");
    tryCatchExecute(projectsModule.handleProjects, "handleProjects");
    tryCatchExecute(tabPaneModule.handleTabPane, "handleTabPane");
    tryCatchExecute(careerFilterSelectModule.careerFilterSelect, "careerFilterSelect");
    tryCatchExecute(formatInsightsSliderModule.formatInsightsSlider, ".js-insights-related");
    tryCatchExecute(trackingModule.startTracking, "startTracking");
    tryCatchExecute(storyTimelineModule.default, "default");
    tryCatchExecute(toggleInsightTagsModule.toggleInsightTags, "toggleInsightTags");
    tryCatchExecute(careerTimelineModule.careerTimeline, "careerTimeline");
    tryCatchExecute(jobOfferVerticalGalleryModule.jobOfferVerticalGallery, "jobOfferVerticalGallery");
    tryCatchExecute(scrollComponent.scrollComponent, "scrollComponent");
    tryCatchExecute(cookiesHelper.showOrHideCookieInfo, "cookiesHelper");
    tryCatchExecute(reportPreviewPopupModule.handleReportPreviewPopup, "handleReportPreviewPopup");
    tryCatchExecute(copyToClipboard.copyToClipboard, "copyToClipboard");
    tryCatchExecute(handleFloadtinButton.handleFloatingButton, "handleFloadtinButton");

  } catch (error) {
    console.error("Error loading or executing modules:", error);
  }
});
  